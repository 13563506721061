
import { defineComponent, ref, onMounted, onBeforeMount, watch } from "vue";
import Datatable from "@/components/agencies/Agencies.vue";
import DateRange from "@/components/daterange/DateRange.vue";
import ApiService from "@/core/services/ApiService";
import _ from "lodash";
import { useRoute } from "vue-router";
import { Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "Leads",
  components: {
    Datatable,
    DateRange,
    Field,
  },
  setup() {
    const route = useRoute();
    const userRole = ref<any>();
    const loading = ref<boolean>(false);
    const keywords = ref<string>("");
    const showing = ref<number>(10);
    const agency = ref<any>("Agencia");
    const tipo = ref<string>("Tipo");
    let searchWord = ref<string>("");
    const agencies = ref<any>([{}]);
    const params = ref<any>();
    const headerConfig = ref<any>([
      {
        name: "Nombre",
        key: "nombre",
        sortable: true,
      },
      {
        name: "Correo",
        key: "correo",
        sortable: true,
      },
      {
        name: "Teléfono",
        key: "telefono",
        sortable: true,
      },
      {
        name: "Tipo",
        key: "tipo",
        sortable: true,
      },
      {
        name: "Fecha",
        key: "fecha",
        sortable: true,
      },
      {
        name: "Ubicación",
        key: "agencia",
        sortable: true,
      },
      {
        name: "Vehículo",
        key: "vehiculo",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: false,
      },
    ]);
    const tableData1 = ref<any>([]);
    const links = ref<any>([]);
    const total = ref<number>(0);
    const from = ref<number>(0);
    const to = ref<number>(0);
    const noResults = ref<boolean>(false);
    const firstDate = ref<string>("");
    const secondDate = ref<string>("");
    const idAgency = ref<any>("");

    const types = ref<any>([{ id:"Tipo", name:"Tipo" }, { id:"Venta", name:"Venta"}, { id:"Apartado", name:"Apartado" }, { id:"Avalúo", name:"Avalúo" }, { id:"Contacto", name:"Contacto" }, { id:"Avalúo $0", name:"Avalúo $0" }]);

    /**
     * @method clearData
     * @params none
     * @return Clear data of table
     */ 
    const clearData = () => {
      tableData1.value.splice(0);
      links.value.splice(0);
      total.value = 0;
      from.value = 0;
      to.value = 0;
    }

    /**
     * @method getPagination
     * @params none
     * @return Leads with pagination
     */
    const getPagination = async (link) => {
      clearData();
      let urlProd = link.url;
      if(urlProd.search('seminuevossolana') > 0){
        urlProd = urlProd.replace('http', 'https');
      }
      await getLeads(urlProd);
    };

    /**
     * @method showingSet
     * @params none
     * @return Leads with data show by quantity
     */
    const showingSet = async () => {
      clearData();
      await getLeads(params.value);
    };

    /**
     * @method getDates
     * @params none
     * @return dates from calendar
     */
    const getDates = (evt) => {
      if(evt.firstDate != undefined){
        firstDate.value = evt.firstDate;
      }
      if(evt.secondDate != undefined){
        secondDate.value = evt.secondDate;
      }
      searching();
    };

    const searchItems = _.debounce(async function () {
      if (searchWord.value != "") {
        tipo.value = "Tipo";
        agency.value = "Agencia";
        firstDate.value = "";
        secondDate.value = "";
        searching();
        console.log(searchWord)
      } else if (searchWord.value === ""){
        searching();
      }
      loading.value = false;
    }, 500);

    

    /**
     * @method searching
     * @params date, type or agency
     * @return Leads list filtered
     */ 
    const searching = async(report = false) => {
      let url = "";
      if(tipo.value != "" && tipo.value != "Tipo"){
        url += "&tipo="+tipo.value;
      }
      if(agency.value != "" && agency.value != "Agencia"){
        url += "&agency="+agency.value;
      }
      if(firstDate.value != ''){
        url += "&ini=" + firstDate.value;
      }
      if(secondDate.value != ''){
        url += "&fin=" + secondDate.value;
      }
      if(searchWord.value != ''){
        url += "&search=" + searchWord.value;
      }
      params.value = url
      if(report){
        let url = "api/leads/prospectos?showing="+showing.value;
        url += "&report=1";
        downloadReport(url);
      }else{
        clearData();
        await getLeads(url);
      }
    }

    /**
     * @method downloadReport
     * @params url: string
     * @return leads in csv file 
     */
    const downloadReport = async (url) => {
      const { data } = await ApiService.get(url);
      let csvContent = "data:text/csv;charset=utf-8," + data;
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Listado_Prospectos.csv");
      link.click();
    };

    /**
     * @method getAgencies
     * @params none
     * @return Agencies 
     */
    const getAgencies = async () => {
      const {data} = await ApiService.get("api/agencies?showing=100");
      agencies.value = data.data.agency.data;
    }

    watch(
      () => route.params.id,
      async newId => {
        clearData();
        console.log("new-id", newId);
        idAgency.value = newId;
        await getLeads();
      }
    )

    /**
     * @method getLeads
     * @params none
     * @return Prospectos 
     */
    const getLeads = async (url = "") => {
      let urlOrigin = "api/leads/prospectos?showing="+showing.value;
      if(url != "" && !url.includes("http")){
        urlOrigin += url;
      }
      if(url != "" && url.includes("http")){
        urlOrigin = url;
      }
      if(route.params.tipo !== "" && route.params.tipo !== undefined){
        urlOrigin += '&tipo='+route.params.tipo;
        tipo.value = route.params.tipo.toString();
      }
      if(route.params.agencia !== "" && route.params.agencia !== undefined){
        urlOrigin += '&agency='+route.params.agencia;
        agency.value = parseInt(route.params.agencia.toString());
      }
      if(idAgency.value != "" && idAgency.value != undefined){
        urlOrigin += '&agency='+idAgency.value;
        agency.value = parseInt(idAgency.value);
        console.log('ENTRA');
      }

      const {data} = await ApiService.get(urlOrigin);
      let prospectos = data.data.data;
      console.log("PROSPECTOS", route.params.id);
      console.log(prospectos);
      if(prospectos.length > 0){
        tableData1.value.splice(0);
        prospectos.forEach((val) => {
          let fecha = val.fecha.split('-');
          tableData1.value.push({
            nombre: val.nombre,
            correo: val.correo,
            telefono: val.telefono,
            tipo: val.tipo,
            fecha: fecha[2]+"/"+fecha[1]+"/"+fecha[0],
            agencia: val.ubicacion,
            vehiculo: val.vehicle,
          });
        });
        links.value = data.data.links;
        total.value = data.data.total;
        from.value = data.data.from;
        to.value = data.data.to;
        noResults.value = false;
      }else{
        tableData1.value.splice(0);
        links.value.splice(0);
        total.value = 0;
        from.value = 0;
        to.value = 0;
        noResults.value = true;
      }
    }

    onBeforeMount(async () => {
      let userRolObj: any = localStorage.getItem("userRole");
      userRole.value = JSON.parse(userRolObj);
      // console.log(userRole.value);
      if (
        userRole.value.id === 2 
      ) {
      headerConfig.value = [
      {
        name: "Nombre",
        key: "nombre",
        sortable: true,
      },
      {
        name: "Correo",
        key: "correo",
        sortable: true,
      },
      {
        name: "Teléfono",
        key: "telefono",
        sortable: true,
      },
      {
        name: "Tipo",
        key: "tipo",
        sortable: true,
      },
      {
        name: "Fecha",
        key: "fecha",
        sortable: true,
      },
      // {
      //   name: "Ubicación",
      //   key: "agencia",
      //   sortable: true,
      // },
      {
        name: "Vehículo",
        key: "vehiculo",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: false,
      },
    ]}});

    onMounted(async () => {
      await getAgencies();
      await getLeads();
    });

    return {
      headerConfig,
      loading,
      keywords,
      tableData1,
      links,
      getPagination,
      total,
      searching,
      showing,
      showingSet,
      from,
      to,
      noResults,
      getLeads,
      tipo,
      clearData,
      getAgencies,
      agencies,
      agency,
      getDates,
      firstDate,
      secondDate,
      downloadReport,
      searchWord,
      searchItems,
      userRole,
      route,
      params,
      types,
      idAgency
    };
  },
});
